* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'NeoSansStd-Regular', sans-serif;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
    width: 8px;
}

div ::-webkit-scrollbar {
  width: 8px;
  padding: 2px;
}
*::-webkit-scrollbar-track {
  box-shadow: #2e2e2e;
}
*::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 30px;
}